import finesApi from '@rosfines/vue-common/common/api/finesApi';

let gettingFinesList;
let gettingFinesListResolve;
let gettingFinesListReject;
const resetGettingFinesState = () => {
  gettingFinesList = new Promise((resolve, reject) => {
    gettingFinesListResolve = resolve;
    gettingFinesListReject = reject;
  });
};
resetGettingFinesState();

const formatFines = (ordinances, type) => {
  const finelist = [];
  for (let i = 0; i < ordinances.length; i++) {
    const ordinance = ordinances[i];
    let fine = {}
    if (ordinance.fine) {
      fine = {
        id: parseInt(ordinance.id, 10),
        status: ordinance.status,
        description: ordinance.fine.offenceShortDescription,
        uin: ordinance.fine.ordinanceNumber,
        fullAmount: (ordinance.amountToPay ? ordinance.amountToPay / 100 : ordinance?.fine?.discountInfo?.fullAmount / 100) * 100,
        amount: (ordinance.amountToPay ? ordinance.amountToPay / 100 : (ordinance.fine.fineAmount || ordinance.fine.amountToPay) / 100) * 100,
        date: ordinance.fine.offenceDateTime ? ordinance.fine.offenceDateTime : (ordinance.fine.statementDate || ordinance.fine.date),
        photos: ordinance.fine.photos ? ordinance.fine.photos : [],
        type,
        number: ordinance.number,
        discountInfo: ordinance.fine.discountInfo || {},
        gibddMapUrl: ordinance.fine.gibddMapUrl,
      }
    } else {
      fine = {
        id: parseInt(ordinance.id, 10),
        status: ordinance.status,
        description: ordinance.offenceShortDescription,
        uin: ordinance.ordinanceNumber,
        fullAmount: ordinance?.discountInfo?.fullAmount,
        amount: (ordinance.discountInfo ? ordinance.discountInfo?.discountAmount / 100 : (ordinance.fineAmount || ordinance.amountToPay) / 100) * 100,
        date: ordinance.offenceDateTime ? ordinance.offenceDateTime : (ordinance.statementDate || ordinance.date),
        photos: ordinance.photos ? ordinance.photos : [],
        type,
        number: ordinance.number,
        discountInfo: ordinance.discountInfo,
        gibddMapUrl: ordinance.gibddMapUrl,
      }
    }

    // fine.discountPercent = (1 - fine.amount / fine.fullAmount) * 100

    // if (order?.date) {
    //   fine.date = order.date
    // }
    if (fine.date) {
      const arDate = fine.date.split(/[- :]/) // это для совместимости с ios
      fine.date = new Date(arDate[0], arDate[1] - 1, arDate[2], arDate[3], arDate[4], arDate[5])
    }

    // if (!fine.description) {
    //   fine.number = order.number
    // }

    finelist.push(fine)
  }
  return finelist;
}

export default {
  namespaced: true,
  state () {
    return {
      ordinancesIds: [],
      chosenOrdinancesIds: [],
      ordinancesList: [],
      amountForOrdinancesResponse: {},
      totalOld: null,
    }
  },
  getters: {
    chosenFinesIds(state) {
      return state.ordinancesIds.reduce((accumulator, id) => {
        accumulator[id] = state.ordinancesList.find((fine) => fine.id === id);
        return accumulator;
      }, []);
    },
  },
  mutations: {
    setFinesList(state, ordinancesList) {
      state.ordinancesList = ordinancesList;
      for(const i in state.ordinancesList) {
        state.ordinancesList[i].id = parseInt(state.ordinancesList[i].id);
      }
      gettingFinesListResolve();
    },
    setAmountForOrdinances(state, data) {
      state.amountForOrdinancesResponse = data;
      if (state.totalOld === null) {
        state.totalOld = Math.floor(data.totalAmount / 100);
      }
    },
    setOrdinancesIds(state, ids) {
      const intIds = ids.map((id) => parseInt(id, 10));
      state.ordinancesIds = intIds;
      state.chosenOrdinancesIds = intIds;
    },
    toggleFine(state, id) {
      id = parseInt(id, 10);
      const index = state.chosenOrdinancesIds.indexOf(id);
      if (index === -1) {
        state.chosenOrdinancesIds.push(id);
      } else {
        delete state.chosenOrdinancesIds[index];
      }
    },
  },
  actions: {
    // resetFinesList({commit}) {
    //   commit("setFinesList", []);
    //   resetGettingFinesState();
    // },
    async waitForFinesList() {
      return gettingFinesList;
    },
    async getFinesList({state, commit}, fineIds) {
      if (fineIds) {
        commit("setOrdinancesIds", fineIds);
      }
      const fineListResponse = await finesApi.getFineListByIds(state.ordinancesIds).catch((e) => {
        gettingFinesListReject();
        if (e?.status === 'error') {
          throw new Error(e.error.errorDesc)
        } else if (e.status !== 'ok') {
          throw new Error('Неизвестный статус ответа сервера')
        }
      })

      let ordinances = [];

      ordinances = ordinances.concat(formatFines(fineListResponse.fines, 'fine'));
      ordinances = ordinances.concat(formatFines(fineListResponse.orders, 'order'));

      commit("setFinesList", ordinances);
      return fineListResponse;
    },
    async getAmountForOrdinances ({state, rootState, commit, dispatch}) {
      await dispatch("waitForFinesList");
      const amountForOrdinancesResponse = await finesApi.getAmountForOrdinances({
        type: 'fine',
        ids: state.chosenOrdinancesIds,
        useBonuses: rootState.bonuses.useBonuses,
        partialAmount: rootState.recurring.partialAmount,
      }).catch(e => {
        throw new Error(e);
      });
      if (amountForOrdinancesResponse) {
        commit("setAmountForOrdinances", amountForOrdinancesResponse);
      }
    },
    async toggleFine({dispatch, commit}, fineId) {
      commit("toggleFine", fineId);
      return await dispatch("getAmountForOrdinances", { type: 'fine' });
    },
  },
}