import finesApi from '@rosfines/vue-common/common/api/finesApi';
import stringHelper from '@rosfines/core-web/helpers/strings';

export default {
  namespaced: true,
  state() {
    return {
      surname: null,
      name: '',
      patronymic: '',
    }
  },
  getters: {
    fullNameObj (state) {
      return {
        surname: state.surname,
        name: state.name,
        patronymic: state.patronymic,
      }
    },
    fullName (state) {
      return `${state.surname} ${state.name} ${state.patronymic}`.trim()
    },
    fullShort (state) {
      return `${state.surname} ${state.name.substring(0, 1)}. ${state.patronymic.substring(0, 1)}.`.trim()
    },
  },
  mutations: {
    saveUserName(state, {
      surname = '',
      name = '',
      patronymic = '',
    }) {
      state.surname = surname;
      state.name = name;
      state.patronymic = patronymic;
    },
  },
  actions: {
    async getUserName({commit, rootState}) {
      let ids = []
      if (rootState.fines.ordinancesIds?.length > 0) {
        ids = rootState.fines.ordinancesIds
      } else {
        ids = rootState.taxes.ordinancesIds
      }
      const fio = await finesApi.getFIOForOrdinances({
        ids,
        type: rootState.recurring.payment.type,
      });
      if (fio) {
        commit("saveUserName", {
          surname: stringHelper.normalizeFIO(fio.lastName).trim(),
          name: stringHelper.normalizeFIO(fio.firstName).trim(),
          patronymic: stringHelper.normalizeFIO(fio.patronymic).trim(),
        })
      }
    }
  }
}