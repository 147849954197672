import * as VueRouter from 'vue-router';
import config from '@/config';

const RegistrationLayout = () => import('@/layout/Registration');
const AddDocLayout = () => import('@/layout/AddDoc');
const AddVehicle = () => import('@/pages/registration/AddVehicle');
const AddCarByGrz = () => import('@/components/blocks/registration/AddCarByGrz');
const AddSts = () => import('@/pages/registration/AddSts');
const AddDriverLicense = () => import('@/pages/registration/AddDriverLicense');
const FinishRegistration = () => import('@/pages/registration/FinishRegistration');

const FineList = () => import('@/pages/FineList');
const FineItem = () => import('@/pages/FineItem');

const AddDriverLicenseBlock = () => import('@/components/blocks/registration/AddDriverLicense');
const AddStsBlock = () => import('@/components/blocks/registration/AddSts');

const Payment = () => import('@/pages/Payment');

const TaxesDocumentAdding = () => import('@/pages/taxes/documents/Add');

const TaxesList = () => import('@/pages/taxes/list/Index')

const router = VueRouter.createRouter({
  mode: 'history',
  history: VueRouter.createWebHistory(),
  base: '/',
  routes: [
    {
      path: '/registration', component: RegistrationLayout, redirect: {name: 'addVehicle' },
      children: [
        { path: 'add-vehicle', component: AddVehicle, name: 'addVehicle' },
        { path: 'add-sts', component: AddSts, name: 'addSts' },
        { path: 'add-driver-license', component: AddDriverLicense, name: 'addDriverLicense' },
        { path: 'finish-registration', component: FinishRegistration, name: 'finishRegistration' },
      ]
    },
    {
      path: '/add-doc', component: AddDocLayout, redirect: { name: 'addDocCar' },
      children: [
        { path: 'add-vehicle', component: AddCarByGrz, name: 'addDocCar' },
        { path: 'add-sts', component: AddStsBlock, name: 'addDocSts' },
        { path: 'add-driver-license', component: AddDriverLicenseBlock, name: 'addDocDriverLicense' },
        { path: 'finish-registration', component: FinishRegistration, name: 'addDocFinish' },
      ]
    },


    { path: '/fine-list', component: FineList, name: 'fineList' },
    { path: '/fine/:type/:id', component: FineItem, name: 'fineItem' },

    { path: '/payment', component: Payment, name: 'payment' },

    { path: '/taxes/documents/add', component: TaxesDocumentAdding, name: 'taxesDocumentsAdd' },

    { path: '/taxes/list', component: TaxesList, name: 'taxesList' },

    {path: '/:pathMatch(.*)*', redirect: {name: 'fineList'}}
  ]
});

router.afterEach(() => {
  window.ym && window.ym(config.YM_SITEKEY, 'hit');
});

router.rfNavigate = (path, params) => {
  if (window.WebToNativeBridge && window.WebToNativeBridge.update && path?.deeplink) {
    const deeplink = path?.matches ? path?.matches.reduce((res, match) => {
      return res.replace('${' + match + '}', params[match]);
    }, path.deeplink) : path?.deeplink;
    console.log(deeplink);
    location.href = deeplink;
  } else if (path?.route) {
    const query = path?.query
      ? path?.query.reduce((res, item) => {
          res[item] = params[item]
          return res;
        }, {})
      : undefined;
    router.push({
      name: path.route,
      query,
      params,
    })
  }
};

export default router;
