class DeviceStorage {
  constructor() {
  }

  get activeFinelistTab() {
    const data = localStorage.getItem("activeFinelistTab")
    return data && JSON.parse(data) || undefined;
  }
  set activeFinelistTab(value) {
    if (value) {
      localStorage.setItem("activeFinelistTab", JSON.stringify(value));
    } else {
      localStorage.removeItem("activeFinelistTab");
    }
  }

  get appConfig() {
    const stored = JSON.parse(localStorage.getItem("appConfig") || "{}");
    return stored.data;
  }
  set appConfig(value) {
    let data = {
      createdTime: new Date().getTime(),
      data: value,
    }
    localStorage.setItem("appConfig", JSON.stringify(data));
  }

  get sbpBanks() {
    const stored = JSON.parse(localStorage.getItem("sbpBanks") || "{}");
    const now = new Date().getTime();
    if (stored && stored.data && stored.createdTime && now - stored.createdTime < 60*60*1000) {
      return stored.data;
    } else {
      localStorage.removeItem("sbpBanks");
      return false;
    }
  }
  set sbpBanks(value) {
    let data = {
      createdTime: new Date().getTime(),
      data: value,
    }
    localStorage.setItem("sbpBanks", JSON.stringify(data));
  }

  get firstLoadingComplete() {
    return Number(localStorage.getItem("firstLoadingComplete")) === 1 || false;
  }
  set firstLoadingComplete(val) {
    localStorage.setItem("firstLoadingComplete", 1);
  }

  get finesCache() {
    return JSON.parse(localStorage.getItem("finesCache") || "{}");
  }
  set finesCache(data) {
    const saved = this.finesCache;
    if (!saved[data.type]) {
      saved[data.type] = {};
    }
    saved[data.type][Number(data.id)] = {
      fines: data.fines,
      orders: data.orders,
    }
    localStorage.setItem("finesCache", JSON.stringify(saved));
  }

  get viewedFines() {
    const stored = JSON.parse(localStorage.getItem("viewedFines") || "{}");
    return stored.data || [];
  }
  set viewedFines(id) {
    const data = this.viewedFines;
    data.push(id)

    localStorage.setItem("viewedFines", JSON.stringify({ data }));
  }

}

const DeviceStorageInstance = new DeviceStorage();

export default DeviceStorageInstance;
