import finesApi from '@rosfines/vue-common/common/api/finesApi';
import defaultAppConfig from '@/constants/defaultAppConfig';
import DeviceStorage from '@/utils/deviceStorage';

const overrideConfig = (config1, config2) => {
  const res = [...config1];
  for (const i in config2) {
    if (!res.find(item => item.id === config2[i]['id'])) {
      res.push(config2[i]);
    }
  }
  return res;
};

export default {
  namespaced: true,
  state () {
    return {
      links: [],
      screen: [],
    }
  },
  getters: {
    getTextInfo: (state) => (screen, id) => {
      // getTextInfo из https://git.rf-tech.ru/rosfines-android/android/-/blob/c279b2a91dede7d2e33f00b0d4a8d083fefffa21/app/src/main/kotlin/ru/rosfines/android/configurablefeatures/Utils.kt
      const textWithLinks = state.screen[screen]?.texts?.find((item) => item.id === id);
      if (textWithLinks?.text) {
        return textWithLinks.text.replace(/\${[^}]*}/g, (match) => {
          const src = match.substr(2, match.length-4);
          const linkData = src.split('<');
          const link = state.links.find((item) => item.id === linkData[1]);
          if (link) {
            return `<a href='${link?.url}' title='${link.title}' target="_blank">${linkData[0]}</a>`;
          } else {
            return match;
          }
        })
      } else {
        return '';
      }
    },
    getLink:(state) => (id) => {
      const link = state.links.find(item => item.id === id);
      return link?.url ? {
        url: link.url,
        title: link.title,
      } : {};
    },
  },
  mutations: {
    saveConfig(state, val) {
      state.links = val.links;
      state.screen = val.screen;
    },
  },
  actions: {
    async loadAppConfig({commit}) {
      const stored = DeviceStorage.appConfig;
      if (stored) {
        try {
          commit('saveConfig', {
            links: overrideConfig(stored.links, defaultAppConfig.links),
            screen: {
              ...defaultAppConfig.screen,
              ...stored.screen,
            }
          });
        } catch(e) {
          commit('saveConfig', defaultAppConfig);
        }
      } else {
        commit('saveConfig', defaultAppConfig);
      }
      const response = await finesApi.get('feature/config/config/app.', {
        channel: localStorage.getItem('channel'),
      });
      if (finesApi.isSuccess(response)) {
        const config = response.data.response.config;
        if (config) {
          DeviceStorage.appConfig = config;
          const screen = config.screen || {};
          for (const i in defaultAppConfig.screen) {
            if (screen[i]) {
              screen[i].texts = overrideConfig(
                screen[i].texts || [],
                defaultAppConfig.screen[i].texts,
              );
            } else {
              screen[i] = defaultAppConfig.screen[i]

            }
          }
          const links = overrideConfig(config.links || [], defaultAppConfig.links)
          const result = {
            links,
            screen,
          };
          commit("saveConfig", result)
        }
      }
    }
  },
}