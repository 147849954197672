import finesApi from '@rosfines/vue-common/common/api/finesApi';

export default {
  namespaced: true,
  state () {
    return {
      useBonuses: false,

      showBonuses: false,
      bonuseBalance: 0,
      bonusesForComissionOnly: false,
    }
  },
  mutations: {
    setUseBonuses(state, val) {
      state.useBonuses = !!val;
    },
    saveBonuses(state, info) {
      state.showBonuses = info.showBonuses;
      state.bonuseBalance = info.limit;
      state.bonusesForComissionOnly = info.type === 'fee';
    },
  },
  actions: {
    async getBalance({commit}) {
      const bonusesBalanceResponse = await finesApi.getBonusBalance( {
        channel: 'FinesAppIOS',
        newResponse: '1',
      }).catch(() => {
        this.bonuses = null;
      })
      if (bonusesBalanceResponse?.showBonuses) {
        commit("saveBonuses", bonusesBalanceResponse)
      }
    }
  },
}