export default {
  "links": [
    {
      "id": "license_agreement",
      "url": "https://rosfines.ru/licenses/license-agreement",
      "title": "Лицензионное соглашение"
    },
    {
      "id": "consent_processing_personal_data",
      "url": "https://rosfines.ru/licenses/pdn",
      "title": "Согласие на обработку персональных данных"
    },
    {
      "id": "personal_data_policy",
      "url": "https://rosfines.ru/licenses/personal-data",
      "title": "Политика в отношении обработки персональных данных ООО \"КАРБОКС\""
    },
    {
      "id": "support_url",
      "url": "https:\/\/communications.rosfines.ru\/support"
    },
    {
      "id": "support_request_url",
      "url": "https:\/\/communications.rosfines.ru\/support\/new-request"
    },
  ],
  "screen": {
    "fines_registration": {
      "texts": [
        {
          "id": "description_for_next",
          "text": "Нажимая на кнопку \"Проверить штрафы\", Вы принимаете ${\"Лицензионное соглашение\"<license_agreement>} и даёте ${согласие на обработку персональных данных<consent_processing_personal_data>}. Подробнее об обработке данных ${здесь<personal_data_policy>}."
        }
      ]
    }
  }
}