<template>
  <teleport to="body">
    <div class="loader-block">
      <div
        v-if="title"
        class="loader-block__title"
      >
        {{title}}
      </div>
      <div
        v-if="subTitle"
        class="loader-block__sub-title"
      >
        {{subTitle}}
      </div>
      <img
        src="@/assets/img/loader-main.svg"
        class="loader-block__img"
        alt=""
      >
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'LoaderBlock',
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  }
}
</script>

<style lang="scss" scoped>
.loader-block {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #FFF;
  z-index:2;
  &__title {
    font-size: 20px;
    margin: 0 0 8px;
  }
  &__sub-title {
    margin: 0 0 12px;
  }
  &__img {
    width: 68px;
    height: 68px;
  }
}
</style>