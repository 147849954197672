import finesApi from '@rosfines/vue-common/common/api/finesApi';
import FineEntity from '@/entities/Fine';
import sleep from '@/utils/sleep';
import DeviceStorageInstance from '@/utils/deviceStorage';

let loadAllFinesSuccess;
const loadAllFines = new Promise((resolve) => {
  loadAllFinesSuccess = resolve;
  DeviceStorageInstance.firstLoadingComplete = true;
})

export default {
  namespaced: true,
  state() {
    return {
      fineList: [],
      requestsStarted: false,
      finesLoading: true,
      finesLoadingComplete: false,

      loadings: {},
      viewedFines: [],

      isFirstLoading: true,
    }
  },
  getters: {
    allFines(state) {
      return state.fineList.map(item => item.fine);
    },
    finesBySource: (state) => (fineBy, fineFor) => {
      return state.fineList.filter((item) => item.fineBy === fineBy && Number(item.fineFor) === Number(fineFor)).map(item => item.fine);
    },
  },
  mutations: {
    pushFine(state, data) {
      const existingIndex = state.fineList.findIndex(item => Number(item.fine.id) === Number(data.fine.id) && item.fine.isOrderFine === data.fine.isOrderFine);
      data.fine.status = data.fine.status || data.fine.fineStatus;
      const fine = new FineEntity(data.fine);
      if (existingIndex === -1) {
        state.fineList.push({
          fineBy: data.type,
          fineFor: Number(data.id),
          fine,
        });
      } else {
        state.fineList[existingIndex].fine = fine;
      }
    },
    setFines(state, list) {
      state.fineList = list;
    },
    startFinesLoading(state) {
      state.finesLoading = true;
    },
    requestsStarted(state) {
      state.requestsStarted = true;
    },
    finesLoadingComplete(state) {
      state.finesLoading = false;
      state.finesLoadingComplete = true;
    },
    startLoading(state, data) {
      const loadings = state.loadings;
      let resolve;
      let reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      }).catch(e => console.log(e));

      if (!loadings[data.type]) {
        loadings[data.type] = {};
      }
      loadings[data.type][Number(data.id)] = {
        promise,
        resolve,
        reject,
        startAt: new Date().getTime(),
      };
      state.loadings = loadings;
    },
    failLoading(state, data) {
      const loading = state.loadings[data.type][Number(data.id)];
      loading?.reject();
      loading.duration = new Date().getTime() - loading.startAt;
    },
    successLoading(state, data) {
      const loading = state.loadings[data.type][Number(data.id)];
      loading?.resolve();
      loading.duration = new Date().getTime() - loading.startAt;
    },
    firstLoadingCompleted(state) {
      state.isFirstLoading = false;
    }
  },
  actions: {
    async waitLoadingFinesFor({state}, {type, id}) {
      if (type && id) {
        return state.loadings[type][Number(id)]?.promise;
      } else {
        return loadAllFines;
      }
    },
    async getAllFines({commit, dispatch, rootState}) {
      commit('startFinesLoading');
      if (DeviceStorageInstance.firstLoadingComplete) {
        commit('firstLoadingCompleted');
      }
      let preloadFinished = false;
      await dispatch('profile/waitLoadingProfile', undefined, {root: true});
      const requests = [];
      const cars = rootState.profile?.profiles[0]?.cars;
      for (const i in cars) {
        const data = {
          type: 'car',
          id: Number(cars[i].id),
        };
        commit('startLoading', data);
        requests.push(() => dispatch('_getFinesByUrl', data));
      }
      const profileDocuments = rootState.profile.profiles[0]?.profileDocuments;
      for (const i in profileDocuments[0]?.driverLicenses) {
        const data = {
          type: 'dl',
          id: Number(profileDocuments[0].driverLicenses[i].id)
        };
        commit('startLoading', data);
        requests.push(() => dispatch('_getFinesByUrl', data));
      }
      for (const i in profileDocuments[0]?.inns) {
        const data = {
          type: 'inn',
          id: Number(profileDocuments[0].inns[i].id)
        };
        commit('startLoading', data);
        requests.push(() => dispatch('_getFinesByUrl', data));
      }
      commit('requestsStarted');
      while (!preloadFinished) {
        const res = await finesApi.get('/preload').catch(async (e) => {
          await sleep( 1000);
        });
        if (finesApi.isSuccess(res)) {
          if (res.data.response.finelist) {
            preloadFinished = true;
          } else if (res.data.response.wait) {
            await sleep(res.data.response.wait * 1000);
          }
        }
      }

      return Promise.all(requests.map(request => request())).catch((e) => {
        console.log(e)
      }).finally(() => {
        commit('finesLoadingComplete');
        loadAllFinesSuccess();
      })
    },
    async _getFinesByUrl({commit, dispatch}, {type, id}) {
      const getCached = () => {
        const cached = DeviceStorageInstance.finesCache;
        if (cached && cached[type] && cached[type][Number(id)]) {
          dispatch('saveFines', {
            type,
            id: Number(id),
            fines: cached[type][Number(id)].fines,
            orders: cached[type][Number(id)].orders,
          });
        }
        return cached;
      };
      const loadFines = async () => {
        const res = await finesApi.get(`fineList/by/${type}/${id}`).catch(e => {
          commit('failLoading', {
            type,
            id: Number(id),
          })
          throw {
            ...e.data.error,
            type,
            id: Number(id),
          }
        });
        // {
        //   "status": "error",
        //   "error": {
        //   "errorCode": 503,
        //     "errorDesc": "Необходимо добавить СТС, В/У или ИНН",
        //     "errorName": "User has not data for fines search"
        // }
        // }
        if (finesApi.isSuccess(res)) {
          DeviceStorageInstance.finesCache = {
            type,
            id: Number(id),
            fines: res.data.response.fines,
            orders: res.data.response.orders,
          };
          dispatch('saveFines', {
            type,
            id: Number(id),
            fines: res.data.response.fines,
            orders: res.data.response.orders,
          });
          commit('successLoading', {
            type,
            id: Number(id),
          });
        } else {
          commit('failLoading', {
            type,
            id: Number(id),
          });
          throw {
            ...res.data.error,
            type,
            id: Number(id),
          }
        }
      }
      return Promise.all([loadFines(), getCached()]).catch((e) => {
        console.log(e);
        throw e;
      });
    },
    saveFines({state, commit}, data) {
      const {type, id} = data;
      const finesList = [...state.fineList.map(fine => ({
        ...fine,
      }))];
      for (const i in data.fines) {
        if (data.fines[i].stsId || data.fines[i].innId || data.fines[i].driverLicenseId) {
          data.fines[i].status = data.fines[i].status || data.fines[i].fineStatus;
          const fineItem = {
            type,
            id: Number(id),
            fine: {
              ...data.fines[i],
              isOrderFine: false,
              status: data.fines[i].status || data.fines[i].fineStatus,
            },
          };
          const existingIndex = finesList.findIndex(item => Number(item.fine.id) === Number(fineItem.fine.id) && item.fine.isOrderFine === fineItem.fine.isOrderFine);
          const fine = new FineEntity(fineItem.fine);
          if (existingIndex === -1) {
            finesList.push({
              fineBy: data.type,
              fineFor: Number(data.id),
              fine,
            });
          } else {
            finesList[existingIndex].fine = fine;
          }
        }
      }
      for (const i in data.orders) {
        ////////////////////////////////
        if (data.orders[i].stsId || data.orders[i].innId || data.orders[i].driverLicenseId) {
          const order = data.orders[i];
          const fineItem = {
            type,
            id: Number(id),
            fine: {
              ...order,
              isOrderFine: true,
              photos: order.photos || order?.fine?.photos,
              offenceDateTime: order.date,
              status: order.status || order.fineStatus,
            },
          };
          const existingIndex = finesList.findIndex(item => Number(item.fine.id) === Number(fineItem.fine.id) && item.fine.isOrderFine === fineItem.fine.isOrderFine);
          const fine = new FineEntity(fineItem.fine);
          if (existingIndex === -1) {
            finesList.push({
              fineBy: data.type,
              fineFor: Number(data.id),
              fine,
            });
          } else {
            finesList[existingIndex].fine = fine;
          }
          ////////////////////////////////
          if (order.fine) {
            if (order.fine.stsId || order.fine.innId || order.fine.driverLicenseId) {
              const orderFineItem = {
                type,
                id: Number(id),
                fine: {
                  ...order.fine,
                  isOrderFine: false,
                  status: order.fine.status || order.fine.fineStatus,
                },
              };
              const existingIndex = finesList.findIndex(item => Number(item.fine.id) === Number(orderFineItem.fine.id) && item.fine.isOrderFine === orderFineItem.fine.isOrderFine);
              const orderFine = new FineEntity(orderFineItem.fine);
              if (existingIndex === -1) {
                finesList.push({
                  fineBy: data.type,
                  fineFor: Number(data.id),
                  fine: orderFine,
                });
              } else {
                finesList[existingIndex].fine = orderFine;
              }
            }
          }
        }
      }
      commit('setFines', finesList);

      // for (const i in data.fines) {
      //   commit('pushFine', {
      //     type,
      //     id: Number(id),
      //     fine: {
      //       ...data.fines[i],
      //       isOrderFine: false,
      //     },
      //   });
      // }
      // console.log(new Date().getTime());
      // for (const i in data.orders) {
      //   const order = data.orders[i];
      //   commit('pushFine', {
      //     type,
      //     id: Number(id),
      //     fine: {
      //       ...order,
      //       isOrderFine: true,
      //       photos: order.photos || order?.fine?.photos,
      //       offenceDateTime: order.date,
      //     },
      //   });
      //   if (order.fine) {
      //     commit('pushFine', {
      //       type,
      //       id: Number(id),
      //       fine: {
      //         ...order.fine,
      //         isOrderFine: false,
      //       },
      //     });
      //   }
      // }
    },
  },
}