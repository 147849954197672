import { createStore } from 'vuex';

import app from '@/store/app';
import appConfig from '@/store/appConfig';
import bonuses from '@/store/bonuses';
import features from '@rosfines/vue-common/vue/store/features';
import fineList from '@/store/fineList';
import fines from '@/store/fines';
// import GBFeatures from '@/store/GBFeatures';
import profile from '@/store/profile';
import recurring from '@/store/recurring';
import sbp from '@/store/sbp';
import taxes from '@/store/taxes';
import userName from '@/store/userName';

export const store = createStore({
  strict: true,
  modules: {
    app,
    appConfig,
    bonuses,
    features,
    fineList,
    fines,
    // GBFeatures,
    profile,
    recurring,
    sbp,
    taxes,
    userName,
  },
  state() {
    return {
      globalLoading: true,
    };
  },
  mutations: {
    setGlobalLoading(state, val) {
      state.globalLoading = !!val;
    },
  },
  actions: {
    auth(context, source) {
      // if (!finesApi.isAuth()) {
      //   finesApi.auth(source);
      // }
    },
    async init({dispatch, commit}) {
      dispatch("appConfig/loadAppConfig", undefined, {root: true});
      await Promise.all([
        dispatch("profile/getProfile", undefined, {root: true}),
        // dispatch("GBFeatures/getFeatures", undefined, {root: true}),
        dispatch("features/loadFeaturesList", {
          channel: localStorage.getItem('channel'),
        }, {root: true}),
      ]).then(() => {
        commit('setGlobalLoading', false);
      }).catch((e) => {
        console.log(e);
        throw new Error('init failed');
      });
    }
  }
});

export default store;
